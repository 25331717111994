// import { doc, getDoc } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import AirData from "../component/mb/AirSection";
import Apartment from "../component/mb/Apartment";
import Covid19 from "../component/mb/Covid19";
import Event from "../component/mb/Event";
import ExchangeRate from "../component/mb/ExchangeRate";
import Forecast from "../component/mb/Forecast";
import Movie from "../component/mb/Movie";
import Pharm from "../component/mb/Pharm";
import Restaurant from "../component/mb/Restaurant";
import Subway from "../component/mb/Subway";
import "../style/css/style.css";
import { db } from "../utils/Firebase";

export default function Mb() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const containerClassName = isMobile
    ? "main_container_mobile"
    : "main_container_tv";
  const itemClassName = isMobile ? "main_item_mobile" : "main_item_tv";
  const titleClassName = isMobile
    ? "main_title_mobile main_title_area"
    : "main_item_tv main_title_area";

  const [state, setState] = useState({
    covid: false,
    air: false,
    money: false,
    event: false,
    house: false,
    subway: false,
    movie: false,
    forecast: false,
    restaurant: false,
    pharm: false,
  });

  const keyListener = useCallback((e) => {
    if (e.key === "GoBack") {
      window.close();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
    };
  }, [keyListener]);

  const fetchState = async () => {
    const stateSnap = await getDoc(doc(db, "public_api", "state"));
    if (stateSnap.exists()) {
      setState(stateSnap.data());
    }
  };

  useEffect(() => {
    fetchState();
  }, []);

  return (
    <div className={containerClassName}>
      <div className={titleClassName}>&nbsp;공공데이터</div>
      {state.covid && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Covid19 />
        </div>
      )}
      {state.air && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <AirData isMobile={isMobile} />
        </div>
      )}
      {state.house && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Apartment />
        </div>
      )}
      {state.money && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <ExchangeRate />
        </div>
      )}
      {state.event && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Event />
        </div>
      )}
      {state.subway && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Subway />
        </div>
      )}
      {state.movie && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Movie />
        </div>
      )}
      {state.forecast && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Forecast />
        </div>
      )}
      {state.restaurant && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Restaurant />
        </div>
      )}
      {state.pharm && (
        <div className={itemClassName} style={{ overflowY: "hidden" }}>
          <Pharm />
        </div>
      )}
    </div>
  );
}
