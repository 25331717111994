// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsGuLrlLspy3-lVGxliRoLtJEnhB25njQ",
  authDomain: "lg-mbsu.firebaseapp.com",
  projectId: "lg-mbsu",
  storageBucket: "lg-mbsu.appspot.com",
  messagingSenderId: "593238996806",
  appId: "1:593238996806:web:126a4b2bde60a07a715747",
  measurementId: "G-YQZ27KPFBZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
