import React from "react";

function StockCard(props) {
  const fixedDigit = (numStr) => {
    if (numStr === "0") {
      return 0;
    } else if (numStr.includes("-")) {
      return "-" + numStr.replace("-", "").padStart(4, "0");
    }
    return numStr.padStart(4, "0");
  };

  const getColor = (numStr) => {
    if (numStr === "0") return "gray";
    else if (numStr.includes("-")) return "#38acec";
    else return "#ff6347";
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          borderLeft: "6px solid gray",
          marginTop: "15px",
          marginBottom: "15px",
          marginLeft: "30px",
          marginRight: "30px",
        }}
      ></div>
      <div style={{ width: "280px", margin: "10px", fontSize: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div>{props.item.name}</div>
          <div>{Number(props.item.clpr).toLocaleString("ko-KR")}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            color: getColor(props.item.vs),
          }}
        >
          <div style={{ fontSize: "50px" }}>
            {fixedDigit(props.item.fltRt) + "%"}
          </div>
          <div>{Number(props.item.vs).toLocaleString("ko-KR")}</div>
        </div>
      </div>
    </div>
  );
}

export default StockCard;
