import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/Firebase";

function StoreObserver(props) {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "flow-info", "page"), (doc) => {
      console.log(doc.data());
      // if (doc.data().select === "stock") {
      //   navigate("/stock");
      // } else if (doc.data().select === "weather") {
      //   navigate("/weather");
      // }
    });
    return unsubscribe;
  }, [navigate]);
  return null;
}

export default StoreObserver;
