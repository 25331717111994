import React, { useCallback, useEffect, useState } from "react";

import { Flipped, Flipper } from "react-flip-toolkit";
import testAvatar from "../logo.svg";
import testImage from "../style/img/noul.jpeg";
import "./AnimatedList.css";

//const listData = [...Array(7).keys()];
const listData = [
  {
    userid: "MUHAN",
    avatar: testAvatar,
    date: "08.03.",
    contents: {
      text: "비 많이 오는 요즘 건조기 밥값하네요. 붉은 노을이 그리워지는 요즘입니다. 사진도 올려봅니다.",
      image: testImage,
    },
  },
  {
    userid: "건빵나라",
    avatar: testAvatar,
    date: "07.29.",
    contents: {
      text: "와이프가 건조 끝나고 쇠로 된 빨래 꺼내다가 화상입을뻔 했어요... 뭔가 경고문구를 주면 좋을 것 같아요",
    },
  },
  {
    userid: "임채경테스트",
    avatar: testAvatar,
    date: "07.28.",
    contents: {
      text: "건조기 행정 후 간혹 세탁물에서 냄새가 나는데 뭐가 문제 일까요?",
    },
  },
  {
    userid: "Douglas",
    avatar: testAvatar,
    date: "07.28.",
    contents: {
      text: "세탁기에도 건조기능이 있던데 그것을 이용하는 것과 건조기의 차이는 뭘까요?",
    },
  },
];
const createCardFlipId = (index) => `listItem-${index}`;

const shouldFlip = (index) => (prev, current) =>
  index === prev || index === current;

const ListItem = ({ index, onClick }) => {
  return (
    <Flipped
      flipId={createCardFlipId(index)}
      stagger="card"
      shouldInvert={shouldFlip(index)}
    >
      <div className="listItem" onClick={() => onClick(index)}>
        <Flipped inverseFlipId={createCardFlipId(index)}>
          <div className="listItemContent">
            <Flipped
              flipId={`avatar-${index}`}
              stagger="card-content"
              shouldFlip={shouldFlip(index)}
              delayUntil={createCardFlipId(index)}
            >
              <div
                className="avatar"
                style={{ backgroundImage: `url(${listData[index].avatar})` }}
              />
            </Flipped>
            <div className="description">
              <Flipped
                flipId={`description-${index}-name`}
                stagger="card-content"
                shouldFlip={shouldFlip(index)}
                delayUntil={createCardFlipId(index)}
              >
                <div>{listData[index].userid}</div>
              </Flipped>
              <Flipped
                flipId={`description-${index}-date`}
                stagger="card-content"
                shouldFlip={shouldFlip(index)}
                delayUntil={createCardFlipId(index)}
              >
                <div>{listData[index].date}</div>
              </Flipped>
            </div>
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

const ExpandedListItem = ({ index, onClick }) => {
  return (
    <Flipped
      flipId={createCardFlipId(index)}
      stagger="card"
      onStart={(el) => {
        setTimeout(() => {
          el.classList.add("animated-in");
        }, 400);
      }}
    >
      <div className="expandedListItem" onClick={() => onClick(index)}>
        <Flipped inverseFlipId={createCardFlipId(index)}>
          <div className="expandedListItemContent">
            <Flipped
              flipId={`avatar-${index}`}
              stagger="card-content"
              delayUntil={createCardFlipId(index)}
            >
              <div
                className="avatar avatarExpanded"
                style={{ backgroundImage: `url(${listData[index].avatar})` }}
              />
            </Flipped>
            <div className="description">
              <Flipped
                flipId={`description-${index}-name`}
                stagger="card-content"
                delayUntil={createCardFlipId(index)}
              >
                <div>{listData[index].userid}</div>
              </Flipped>
              <Flipped
                flipId={`description-${index}-date`}
                stagger="card-content"
                delayUntil={createCardFlipId(index)}
              >
                <div>{listData[index].date}</div>
              </Flipped>
            </div>
            <div className="additional-content">
              <div>{listData[index].contents.text}</div>
              {listData[index].contents.image ? (
                <div className="attachedImage">
                  <img src={listData[index].contents.image} alt="attached" />
                </div>
              ) : (
                listData.slice(0, 2).map((data, i) => <div key={i} />)
              )}
            </div>
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

function AnimatedList(props) {
  const [showing, setShowing] = useState(false);
  const [focused, setFocused] = useState();
  const [containerClass, setContainerClass] = useState(
    "staggered-list-content type1 show"
  );

  const clickHandler = (index) =>
    setFocused((prev) => (prev === index ? null : index));
  const keyListener = useCallback(
    (e) => {
      console.log(e.key);
      if (e.key === "ArrowDown") {
        setFocused((prev) =>
          prev === undefined
            ? 0
            : prev === listData.length - 1
            ? prev
            : prev + 1
        );
      } else if (e.key === "ArrowUp") {
        setFocused((prev) => (prev === undefined || prev === 0 ? 0 : prev - 1));
      } else if (e.key === "Enter") {
        //setContainerClass("staggered-list-content type1 show");
        setShowing(true);
      } else if (e.key === "GoBack") {
        if (!showing) {
          window.close();
          return;
        }
        setContainerClass((prev) => `${prev.replace("type1", "")} type1_off`);
      }
    },
    [showing]
  );

  const toggleListener = useCallback(
    (e) => {
      if (e.target.tagName !== "HTML") return;
      if (!showing) {
        keyListener({ key: "Enter" });
      } else {
        keyListener({ key: "GoBack" });
      }
    },
    [showing, keyListener]
  );

  const handleAnimation = (e) => {
    if (e.animationName === "type_ani1_off") {
      setContainerClass((prev) => `${prev.replace("show", "")}`);
      setFocused(undefined);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", toggleListener);
    window.addEventListener("animationend", handleAnimation);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", toggleListener);
      window.removeEventListener("animationend", handleAnimation);
    };
  }, [keyListener, toggleListener]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: "20px",
          top: "20px",
          color: "red",
          fontSize: "30px",
        }}
        onClick={() => {
          if (!containerClass.includes("show")) {
            keyListener({ key: "Enter" });
          } else {
            keyListener({ key: "GoBack" });
          }
        }}
      >
        Connected
      </div>
      <Flipper
        flipKey={focused}
        className={containerClass}
        spring="gentle"
        staggerConfig={{
          card: {
            reverse: focused !== null,
          },
        }}
        decisionData={focused}
      >
        <ul className="list">
          {listData.map((data, index) => {
            return (
              <li key={index}>
                {index === focused ? (
                  <ExpandedListItem index={focused} onClick={clickHandler} />
                ) : (
                  <ListItem index={index} key={index} onClick={clickHandler} />
                )}
              </li>
            );
          })}
        </ul>
      </Flipper>
    </>
  );
}

export default AnimatedList;
