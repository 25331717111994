import { useEffect, useState } from "react";
import { getRestaurantData } from "../../api/network";
import "../../style/css/style.css";
import Title from "./Title";

const Restaurant = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getRestaurantData(setData);
  }, []);

  return (
    <div className="restaurant_main">
      <Title name={data?.title} />
      {data ? (
        <>
          {data.dataList?.map((item, index) => (
            <div key={index}>
              {item.title} <br /> {item.data}
            </div>
          ))}
        </>
      ) : (
        <h4>서비스 준비 중입니다.</h4>
      )}
    </div>
  );
};

export default Restaurant;