import { useEffect, useState } from "react";
import Title from "./Title";
import { getApartmentData } from "../../api/network";

const Apartment = () => {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    getApartmentData(setData);
  }, []);

  return (
    <div className="apartment_main">
      <Title name={data?.title} />
      {data ? (
        <div className="apartment_list">
          {data.dataList?.slice(0, 5).map((item, index) => {
            return (
              <div key={index}>
                <div className="apartment_list_name">
                  <span>
                    {item.title}
                  </span>
                </div>
                <div className="apartment_list_price">
                  <span>{item.data}</span>
                </div>
              </div>
            );
        })}
      </div>
      ) : (
        <h4>조회 결과가 없습니다.</h4>
      )}
    </div>
  );
};

export default Apartment;