import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import StockCard from "../component/StockCard";
import "../style/css/marquee.css";
import { getDate } from "../utils/DateUtils";

var serviceKey =
  "tjm62FrQgXUk1dyiZ8uwzGXN%2Bo02KJBl%2BmP2Xy78VwXchJQwBRbdM15EpccDW5HfdQ0Wl7jvh2pcDkRup9QU0g%3D%3D";

var items = [
  "LG",
  "LG전자",
  "LG화학",
  "LG이노텍",
  "LG유플러스",
  "LG디스플레이",
  "NAVER",
  "SK하이닉스",
  "현대차",
  "삼성전자",
  "카카오",
];

function Stock(props) {
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    setStocks([]);
    items.forEach((item) => {
      var xhr = new XMLHttpRequest();
      var url =
        "https://api.odcloud.kr/api/GetStockSecuritiesInfoService/v1/getStockPriceInfo"; /*URL*/
      var queryParams = "?serviceKey=" + serviceKey; /*Service Key*/
      queryParams +=
        "&resultType=" + encodeURIComponent("json"); /*Result Type*/
      queryParams += "&beginBasDt=" + getDate(-4);
      queryParams += "&itmsNm=" + item; /*Item Name*/
      xhr.open("GET", url + queryParams);
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          try {
            var result = JSON.parse(this.responseText);
            if (result.response.body.totalCount === 0) {
              console.error("NO DATA!");
              return;
            }
            var item = result.response.body.items.item[0];
            var val = {
              name: item.itmsNm,
              clpr: item.clpr,
              vs: item.vs,
              fltRt: item.fltRt,
            };
            console.log(val);
            setStocks((oldValue) => {
              return [...oldValue, val];
            });
          } catch (e) {
            console.log(e.message);
          }
        }
      };
      xhr.onerror = function (e) {
        console.log(e);
      };
      xhr.send("");
    });
  }, []);
  return (
    <div style={{ position: "absolute", bottom: "30px" }}>
      <Marquee
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
        }}
        speed="100"
      >
        {stocks.map((stock, index) => (
          <StockCard key={index} item={stock} />
        ))}
      </Marquee>
    </div>
  );
}

export default Stock;
