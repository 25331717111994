import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import "../style/css/marquee.css";
import { getDate } from "../utils/DateUtils";

var serviceKey =
  "tjm62FrQgXUk1dyiZ8uwzGXN%2Bo02KJBl%2BmP2Xy78VwXchJQwBRbdM15EpccDW5HfdQ0Wl7jvh2pcDkRup9QU0g%3D%3D";

function Weather(props) {
  const { demo } = props;
  const [forecast, setForecast] = useState("");

  useEffect(() => {
    if (demo) {
      setForecast("○ (주요뉴스) 9호선 지하철 탈선사고로 운행중단되어 오후 10시쯤 복구 예정 ○ (강수) 4일(목) 전남권과 경남권, 제주도에, 5일(금) 오전 남부지방과 제주도에 비가 오겠습니다. 5일(금) 오후~11일(목) 전국 대부분 지역에 비가 오겠으나, 5일(금) 오후와 8일(월) 제주도에는 비가 소강상태를 보이겠습니다. ○ (기온) 이번 예보기간 아침 기온은 20~25도, 낮 기온은 26~32도로 평년(최저기온 20~22도, 최고기온 26~30도)과 비슷하겠습니다. ○ (해상) 4일(목)은 동해상에 물결이 1.0~3.5m로 매우 높게 일겠습니다. ○ (주말전망) 6일(토)~7일(일)은 전국에 비가 오겠습니다. 아침 기온은 21~25도, 낮 기온은 27~32도가 되겠습니다. * (강수 유의 사항 및 변동성) 이번 예보기간 동안 정체전선의 영향으로 비가 오는 지역이 많겠고, 특히, 매우 강하고 많은 비가 내릴 가능성이 있겠습니다. 한편, 정체전선의 위치를 비롯한 우리나라 주변 기압계 변화에 따라 강수지역과 시점 등 예보 변동성이 크겠으니, 앞으로 발표되는 최신 예보를 참고하기 바랍니다.");
      const timer = setInterval(() => {
        const clientId = localStorage.getItem("clientId");
        window.connectManager.sendMessage(clientId, 'up');
      }, 60000); // 60초마다 갱신 시도

    } else {
      console.log(new Date().getHours());
      var xhr = new XMLHttpRequest();
      var url =
        "https://apis.data.go.kr/1360000/MidFcstInfoService/getMidFcst"; /*URL*/
      var queryParams = "?serviceKey=" + serviceKey; /*Service Key*/
      queryParams += "&pageNo=" + encodeURIComponent("1"); /**/
      queryParams += "&numOfRows=" + encodeURIComponent("10"); /**/
      queryParams += "&dataType=" + encodeURIComponent("JSON"); /**/
      queryParams += "&stnId=" + encodeURIComponent("108"); /**/
      queryParams +=
        "&tmFc=" + getDate(new Date().getHours() > 6 ? 0 : -1) + "0600" /**/;
  
      xhr.open("GET", url + queryParams);
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          try {
            var result = JSON.parse(this.responseText);
            console.log(result);
            setForecast(result.response.body.items.item[0].wfSv);
          } catch (e) {
            console.log(e.message);
          }
        }
      };
  
      xhr.send("");
    }
  }, [demo]);
  return (
    <div style={{ position: "absolute", bottom: "30px" }}>
      <Marquee
        style={{
          fontSize: "70px",
          backgroundColor: "rgba(255, 0, 21, 0.8)",
          color: "white",
        }}
        speed="100"
        delay="2"
      >
        {forecast}
      </Marquee>
    </div>
  );
}

export default Weather;
