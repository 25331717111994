import React, { useEffect, useState } from "react";
import { getCovid19Data } from "../../api/network";
import "../../style/css/style.css";
import Title from "./Title";

const Covid19 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getCovid19Data(setData);
  }, []);

  return (
    <>
      <div className="covid19_main">
        <Title name={data?.title} />
        {data ? (
          <>
            {data.dataList?.map((item, index) => (
              <div key={index}>
                {item.title} <br />
                {item.data}
              </div>
            ))}
          </>
        ) : (
          <h4>서비스 준비 중입니다.</h4>
        )}
      </div>
    </>
  );
};

export default Covid19;