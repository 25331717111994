import { useCallback, useEffect, useState } from "react";

function TvHandler(props) {
  const [clientId, setClientId] = useState();
  const keyListener = useCallback(
    (e) => {
      if (e.key) {
        const message = {
          keyEvent: {
            code: e.code,
            key: e.key,
            keyCode: e.keyCode,
            type: e.type,
          },
        };

        // if (window.tvlog) window.tvlog(message);
        if (clientId) {
          window.connectManager.sendMessage(clientId, message);
        }

        if (e.key === "GoBack") {
          //window.close();
        }
      }
    },
    [clientId]
  );
  const clickListener = useCallback(
    (e) => {
      keyListener({
        code: "Enter",
        key: "Enter",
        keyCode: -1,
        type: "click",
      });
    },
    [keyListener]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);
  useEffect(() => {
    if (!window.connectsdk) return;
    window.connectManager = new window.connectsdk.ConnectManager();
    window.connectManager.on("join", function (client) {
      // window.tvlog("client joined with id " + client.id);
      window.connectManager.sendMessage(client.id, 'up');
      localStorage.setItem("clientId", client.id)
      setClientId(client.id);
    });
    window.connectManager.on("message", function (data) {
      // window.tvlog("client sendmessage", data);
      var from = data.from;
      var message = data.message;
      var messageString;
      var isJSON = false;

      if (typeof message == "string") {
        messageString = message;
      } else if (typeof message == "object") {
        messageString = JSON.stringify(message);
        isJSON = true;
      }
      // window.tvlog(
      //   `Received message(JSON ${isJSON}) from ${from}:`,
      //   messageString
      // );
    });
    window.connectManager.init();
  }, []);
  return null;
}

export default TvHandler;
