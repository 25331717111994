import axios from "axios";
import convert from "xml-js";

// 개별 공공 데이터 정보
const ContentsObject = function (title, dataList, imageUrl = "") {
  this.title = title;
  this.dataList = dataList; // array
  this.image = imageUrl;
};

// ContentsObject.dataList에 저장되는 상세 정보
const ContentsItem = function (title, data, imageUrl = "") {
  this.title = title;
  this.data = data; // array
  this.imageUrl = imageUrl;
};

// 코로나19 발생동향
const addComma = (number) => {
  let n = parseInt(number);
  return n.toLocaleString();
};

const compare = (today, yesterday) => {
  let result = today - yesterday;
  if (result > 0) {
    return "+" + addComma(result);
  } else if (result < 0) {
    return addComma(result);
  } else {
    return 0;
  }
};

export const getCovid19Data = async (setData) => {
  try {
    axios
      .get(
        "http://openapi.seoul.go.kr:8088/776d53474f7972613131367a4d744d50/json/TbCorona19CountStatus/1/2/"
      )
      .then((res) => {
        console.log("data : " + JSON.stringify(res.data.TbCorona19CountStatus));
        let result = res.data.TbCorona19CountStatus;
        if (result.RESULT.CODE === "INFO-000") {
          console.log("코로나19 정상 처리되었습니다~!");
          const contentItems = [];
          contentItems.push(
            new ContentsItem(
              "서울시 신규 확진자",
              addComma(result.row[0].SN_HJ) +
                " (전날 대비: " +
                compare(result.row[0].SN_HJ, result.row[1].SN_HJ) +
                ")"
            )
          );
          contentItems.push(
            new ContentsItem(
              "서울시 당일 사망자",
              addComma(result.row[0].DAY_DEATH) +
                " (전날 대비: " +
                compare(result.row[0].DAY_DEATH, result.row[1].DAY_DEATH) +
                ")"
            )
          );
          contentItems.push(
            new ContentsItem(
              "대한민국 신규 확진자",
              addComma(result.row[0].N_HJ) +
                " (전날 대비: " +
                compare(result.row[0].N_HJ, result.row[1].N_HJ) +
                ")"
            )
          );
          contentItems.push(
            new ContentsItem(
              "대한민국 당일 사망자",
              addComma(result.row[0].ALL_DAY_DEATH) +
                " (전날 대비: " +
                compare(
                  result.row[0].ALL_DAY_DEATH,
                  result.row[1].ALL_DAY_DEATH
                ) +
                ")"
            )
          );
          const contents = new ContentsObject(
            "코로나19 발생동향",
            contentItems
          );
          console.log("Covid19 contents return: " + JSON.stringify(contents));
          setData(contents);
        }
      });
  } catch (error) {
    console.log(error);
  }
};
// 코로나19 발생동향

// 가양1동 단기예보
export const ForecastCategory = new Map([
  ["POP", { name: "강수확률", unit: "%" }],
  ["TMP", { name: "기온", unit: "℃" }],
  ["SKY", { name: "하늘상태", codeVal: ["", "맑음", "", "구름많음", "흐림"] }],
  ["REH", { name: "습도", unit: "%" }],
  [
    "PTY",
    {
      name: "강수형태",
      codeVal: ["없음", "비", "비/눈", "눈", "소나기"],
    },
  ],
  ["VEC", { name: "풍향", unit: "deg" }],
  ["WSD", { name: "풍속", unit: "m/s" }],
]);

const dateFormat = () => {
  let date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;

  return date.getFullYear() + "" + month + "" + day;
};

const timeFormat = (retry) => {
  if (retry) {
    return "0500";
  } else {
    return "0800";
  }
};

export const getForecastData = async (setData) => {
  const SERVICE_KEY =
    "jMY68N7u8CnRSCrxrxL1d6Fe66DrtaEUvrvGkRrePmo8To/br7mlZskRDzuGW/s6VI3EfbSSr6FMXRW2mj2q6A==";

  let retry = false;
  const loadData = async () => {
    try {
      await axios
        .get(
          "https://cors2.bonkab.com/http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst",
          {
            params: {
              serviceKey: SERVICE_KEY,
              dataType: "JSON",
              base_date: dateFormat(),
              base_time: timeFormat(retry),
              nx: 55,
              ny: 127,
            },
          }
        )
        .then((res) => {
          if (res.data.response.header.resultCode === "00") {
            const jsonRes = res.data.response.body.items.item;
            console.log(jsonRes);
            const contentItems = [];

            jsonRes
              ?.filter(function (item) {
                if (ForecastCategory.get(item.category)) {
                  return true; // skip
                }
                return false;
              })
              ?.map(function (item) {
                const data =
                  (ForecastCategory.get(item.category).unit
                    ? item.fcstValue + ForecastCategory.get(item.category).unit
                    : ForecastCategory.get(item.category).codeVal[
                        item.fcstValue
                      ]);
                return contentItems.push(new ContentsItem(ForecastCategory.get(item.category).name, data));
              });

            const contents = new ContentsObject(
              "가양1동 단기예보",
              contentItems
            );
            console.log(
              "Forecast contents return: " + JSON.stringify(contents)
            );
            setData(contents);
          } else if (!retry) {
            retry = true;
            loadData();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  loadData();
};
// 가양1동 단기예보

// 안심식당 정보
export const getRestaurantData = async (setData) => {
  const SERVICE_KEY =
    "5ade7420d876b37482c33c4c520e6ae038eace822757bfb71f9e3784d094e549";

  try {
    await axios
      .get(
        "https://cors2.bonkab.com/http://211.237.50.150:7080/openapi/" +
          SERVICE_KEY +
          "/json/Grid_20200713000000000605_1/1/10/"
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.Grid_20200713000000000605_1.result.code === "INFO-000") {
          const jsonRes = res.data.Grid_20200713000000000605_1.row;
          const contentItems = [];
          jsonRes?.map(function (item) {
            const title =
              item.RELAX_RSTRNT_NM + " - " + item.RELAX_GUBUN_DETAIL;
            const data = item.RELAX_ADD1 + " (" + item.RELAX_RSTRNT_TEL + ")";
            return contentItems.push(new ContentsItem(title, data));
          });

          const contents = new ContentsObject("안심식당 정보", contentItems);
          console.log(
            "Restaurant contents return: " + JSON.stringify(contents)
          );
          setData(contents);
        }
      });
  } catch (error) {
    console.log(error);
  }
};
// 안심식당 정보

// 공휴일 약국정보
export const getPharmData = async (setData) => {
  const SERVICE_KEY =
    "jMY68N7u8CnRSCrxrxL1d6Fe66DrtaEUvrvGkRrePmo8To/br7mlZskRDzuGW/s6VI3EfbSSr6FMXRW2mj2q6A==";

  try {
    await axios
      .get(
        "https://cors2.bonkab.com/http://apis.data.go.kr/B552657/ErmctInsttInfoInqireService/getParmacyListInfoInqire",
        {
          params: {
            serviceKey: SERVICE_KEY,
            Q0: "서울특별시",
            Q1: "강서구",
            QT: 8,
            pageNo: 1,
            numOfRows: 10,
          },
        }
      )
      .then((res) => {
        console.log(res.data.response);
        if (res.data.response.header.resultCode === "00") {
          const jsonRes = res.data.response.body.items.item;
          const contentItems = [];
          jsonRes?.map(function (item) {
            const title = item.dutyName + " (" + item.dutyTel1 + ")";
            const data = item.dutyAddr;
            return contentItems.push(new ContentsItem(title, data));
          });

          const contents = new ContentsObject("공휴일 약국정보", contentItems);
          console.log("Pharmacy contents return: " + JSON.stringify(contents));
          setData(contents);
        }
      });
  } catch (error) {
    console.log(error);
  }
};
// 공휴일 약국정보

// 내 주변 행사/공연/축제
export const getEventData = async (setData) => {
  try {
    await axios.get(
      "http://api.visitkorea.or.kr/openapi/service/rest/KorService/locationBasedList",
      {
        params: {
          serviceKey: encodeURI(
              "KA024AK8Jjs1twEyxtKqgP8mwX6IfBagCqSZavMxs9aNYpuhg9V8SyoyNknE8ANWtif+cgoKYNvoI3DEtoq+4g=="
            ),
            numOfRows: "2",
            pageNo: "1",
            MobileOS: "ETC",
            MobileApp: "ApiTestApp",
            listYN: "Y",
            arrange: "A",
            contentTypeId: "15", //type,
            mapX: "126.8357822", //mapX,
            mapY: "37.5626316", //mapY,
            radius: "10000", //distance,
            _type: "json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        const data = res?.data?.response.body.items.item;
        const contentItems = [];
        data?.map((item) => {
          contentItems.push(
            new ContentsItem(item.title, item.addr1, item.firstimage)
          );
          return contentItems;
        })
        var contents = new ContentsObject("내 주변 행사/공연/축제", contentItems);
        console.log("Event contents return: " + JSON.stringify(contents));
        setData(contents);
    })
  } catch (error) {
    console.log(error);
  }
};
// 내 주변 행사/공연/축제

//환율 정보
export const getExchangeRateData = async (setData) => {
  const SERVICE_KEY = "tusZitMHWw4ajlzRHSfbTjVEVOOot7cA";

  try {
    await axios
      .get(
        "https://cors2.bonkab.com/https://www.koreaexim.go.kr/site/program/financial/exchangeJSON",
        {
          params: {
            authkey: SERVICE_KEY,
            data: "AP01",
          },
        }
      )
      .then((res) => {
        const jsonRes = res.data;
        console.log(jsonRes);
        const contentItems = [];
        jsonRes
          ?.filter(function (item) {
            if (item.cur_unit === "EUR") {
              return true;
            } else if (item.cur_unit === "USD") {
              return true;
            } else if (item.cur_unit === "JPY(100)") {
              return true;
            } else if (item.cur_unit === "HKD") {
              return true;
            } else if (item.cur_unit === "THB") {
              return true;
            }
            return false;
          })
          ?.map(function (item) {
            const title = item.cur_nm + " (" + item.cur_unit + ")";
            const data =
              "전신환(송금) 받으실때: " +
              item?.ttb +
              ", 전신환(송금) 보내실때: " +
              item?.tts;
            return contentItems.push(new ContentsItem(title, data));
          });

        const contents = new ContentsObject("환율 정보", contentItems);
        console.log("Exchange contents return: " + JSON.stringify(contents));
        setData(contents);
      });
  } catch (error) {
    console.log(error);
  }
};
//환율 정보

// 일별 박스오피스 Top 5
const getYesterday = () => {
  let today = new Date();
  let yesterday = new Date(today.setDate(today.getDate() - 1));
  let year = yesterday.getFullYear();
  let month = yesterday.getMonth() + 1;
  month = month < 10 ? "0" + month : "" + month;
  let day = yesterday.getDate();
  day = day < 10 ? "0" + day : "" + day;

  return "" + year + month + day;
};

export const getMovieData = async (setData) => {
  try {
    axios.get(
      `http://kobis.or.kr/kobisopenapi/webservice/rest/boxoffice/searchDailyBoxOfficeList.json?key=b849f220d83fd57f2a3f2b4a7dfab116&targetDt=${getYesterday()}&itemPerPage=5`
    )
    .then((res) => {
      console.log(res);
      const data =res.data?.boxOfficeResult?.dailyBoxOfficeList;
      const contentItems = [];
      data?.map((item, index) => {
        contentItems.push(
          new ContentsItem(`${++index}. ${item.movieNm}`, `${item.openDt} 개봉`)
        );
        return contentItems;
      })
      var contents = new ContentsObject("일별 박스오피스 Top 5", contentItems);
      console.log("Movie contents return: " + JSON.stringify(contents));
      setData(contents);
    });
  } catch (error) {
    console.log(error);
  }
}
// 일별 박스오피스 Top 5

// 마곡나루역 지하철 실시간 도착정보
export const getSubwayData = async (setData) => {
  try {
    axios.get(
      "http://swopenapi.seoul.go.kr/api/subway/75786f677774657338386c75586657/json/realtimeStationArrival/1/10/마곡나루"
    )
    .then((res) => {
      console.log(res);
      const data = res.data?.realtimeArrivalList;
      const contentItems = [];
      data?.map((item) => {
        contentItems.push(
          new ContentsItem(`[${item.updnLine}] ${item.trainLineNm}`, item.arvlMsg2)
        );
        return contentItems;
      })
      var contents = new ContentsObject("마곡나루역 지하철 실시간 도착정보", contentItems);
      console.log("Subway contents return: " + JSON.stringify(contents));
      setData(contents);
    });
  } catch (error) {
    console.log(error);
  }
}
// 마곡나루역 지하철 실시간 도착정보

// 강서구 아파트 실거래가 6월 Top 5
export const getApartmentData = async (setData) => {
  try {
    fetch(
      "http://cors.bonkab.com/http://openapi.molit.go.kr/OpenAPI_ToolInstallPackage/service/rest/RTMSOBJSvc/getRTMSDataSvcAptTradeDev?serviceKey=XC5r7%2B5%2Fozrmt68RNGYCtCd0piNNsVDZyvkJPHOmHe2RfNOvYfxtTFsUKRoEdop1Q0CbyvSE2XP00gP8jhXTBA%3D%3D&pageNo=1&numOfRows=100&LAWD_CD=11500&DEAL_YMD=202206",
      {
        method: "get",
      }
    )
    .then((res) => res.text())
    .then((xml) => {
      var result = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 2 })
      );
      const data = result.response.body.items.item;
      console.log(data);

      data.sort(function (a, b) {
        const aa = Number.parseInt(a["거래금액"]["_text"]);
        const bb = Number.parseInt(b["거래금액"]["_text"]);
        return bb - aa;
      });

      const contentItems = [];
      data?.map((item) => {
        contentItems.push(
          new ContentsItem(
            `${item["아파트"]["_text"]} (${
              item["층"]["_text"]
            }층, ${Number.parseFloat(item["전용면적"]["_text"]).toFixed(0)}㎡, ${item["건축년도"]["_text"]})`,
            item["거래금액"]["_text"]
            )
        );
        return contentItems;
      })
      var contents = new ContentsObject("강서구 아파트 실거래가 6월 Top 5", contentItems);
      console.log("Apartment contents return: " + JSON.stringify(contents));
      setData(contents);
    })
  } catch (error) {
    console.log(error);
  }
}
// 강서구 아파트 실거래가 6월 Top 5

// 서울 강서구 가양1동 대기/오염 정보
const middleware = "https://cors.bonkab.com/";

const url =
  "https://apis.data.go.kr/B552584/ArpltnInforInqireSvc/getMsrstnAcctoRltmMesureDnsty?stationName=%EC%A2%85%EB%A1%9C%EA%B5%AC&dataTerm=month&pageNo=1&numOfRows=100&returnType=json";

  const serviceKey =
  "tjm62FrQgXUk1dyiZ8uwzGXN%2Bo02KJBl%2BmP2Xy78VwXchJQwBRbdM15EpccDW5HfdQ0Wl7jvh2pcDkRup9QU0g%3D%3D";

const demoData = {
  body: {
    totalCount: 719,
    items: [
      {
        so2Grade: "1",
        coFlag: null,
        khaiValue: "76",
        so2Value: "0.003",
        coValue: "0.3",
        pm10Flag: null,
        pm10Value: "26",
        o3Grade: "2",
        khaiGrade: "2",
        no2Flag: null,
        no2Grade: "1",
        o3Flag: null,
        so2Flag: null,
        dataTime: "service error",
        coGrade: "1",
        no2Value: "0.014",
        pm10Grade: "1",
        o3Value: "0.061",
      },
    ],
    pageNo: 1,
    numOfRows: 100,
  },
  header: {
    resultMsg: "NORMAL_CODE",
    resultCode: "00",
  },
};

const airList = [
  "통합대기환경",
  "아황산 가스",
  "일산화탄소",
  "오존",
  "일산화질소",
  "미세먼지",
];

const gradeString = { 1: "좋음", 2: "보통", 3: "나쁨", 4: "매우나쁨" };

export const getAirData = async (setData) => {
  try {
    axios
      .get(middleware + url + "&serviceKey=" + serviceKey)
      .then((response) => {
        console.log(response);
        let current;
        if (response.data.response) {
          current = response.data.response.body.items[0];
        } else {
          current = demoData.body.items[0];
        }
        const airTemp = [
          { grade: current.khaiGrade, value: current.khaiValue },
          { grade: current.so2Grade, value: current.so2Value },
          { grade: current.coGrade, value: current.coValue },
          { grade: current.o3Grade, value: current.o3Value },
          { grade: current.no2Grade, value: current.no2Value },
          { grade: current.pm10Grade, value: current.pm10Value },
        ];

        const contentItems = [];
        airTemp.map((item, index) => {
          contentItems.push(
            new ContentsItem(airList[index], `상태 : ${gradeString[item.grade]} [${item.value}]`)
          );
          return contentItems;
        })

        var contents = new ContentsObject("서울 강서구 가양1동 대기/오염 정보", contentItems);
        console.log("AirSection contents return: " + JSON.stringify(contents));
        setData(contents);
      })
  } catch (error) {
    console.log(error);
  }
}
// 서울 강서구 가양1동 대기/오염 정보