import { useNavigate } from "react-router-dom";

function Home(props) {
  const navigate = useNavigate();
  return (
    <div>
      <button onClick={() => navigate("/stock")}>stock</button>
      <button onClick={() => navigate("/weather")}>weather</button>
      <button onClick={() => navigate("/feed")}>feed</button>
      <button onClick={() => navigate("/mb")}>mb</button>
    </div>
  );
}

export default Home;
