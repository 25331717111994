import { createTheme, ThemeProvider } from "@mui/material";
import { HashRouter, useRoutes } from "react-router-dom";
import StoreObserver from "./component/StoreObserver";
import TvHandler from "./component/TvHandler";
import AnimatedList from "./screen/AnimatedList";
import Home from "./screen/Home";
import Mb from "./screen/Mb";
import Stock from "./screen/Stock";
import Weather from "./screen/Weather";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontSize: 32,
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <HashRouter>
        <StoreObserver />
        <TvHandler />
        <AppRoutes />
      </HashRouter>
    </ThemeProvider>
  );
}

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/feed", element: <AnimatedList /> },
    { path: "/weather", element: <Weather /> },
    { path: "/demo", element: <Weather demo/> },
    { path: "/stock", element: <Stock /> },
    { path: "/mb", element: <Mb /> },
  ]);
  return routes;
};

export default App;
