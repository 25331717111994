import { useEffect, useState } from "react";
import Title from "./Title";
import { getAirData } from "../../api/network";

const AirSection = (props) => {
  const [data, setData] = useState(null);
  const airList = [
    "통합대기환경",
    "아황산 가스",
    "일산화탄소",
    "오존",
    "일산화질소",
    "미세먼지",
  ];
  
  useEffect(() => {
    getAirData(setData);
  }, []);

  return (
    <>
      <div className="air-area">
        <div>
          <Title name={data?.title} />
          {data ? (
            <div className="air-area-container">
              {data.dataList?.map((item, index) => (
                <Card
                  key={airList[index]}
                  title={item.title}
                  data={item.data}
                  isMobile={props.isMobile}
                />
              ))}
            </div>
          ) : (
            <h4>조회 결과가 없습니다.</h4>
          )}
        </div>
      </div>
    </>
  );
};

const Card = (props) => {
  // const gradeColor = {
  //   1: "#51A1F7",
  //   2: "#5AC352",
  //   3: "#F1A943",
  //   4: "#D7514E",
  // };

  return (
    <div className="gauge-item" style={{ display: "flex", margin: "10px" }}>
      {/* <div className={props.isMobile ? "name box air_mobile" : "name box"}>
        <div style={{ backgroundColor: gradeColor[props.grade] }}>
          {props.value}
        </div>
      </div> */}
      <div
        className="text-area"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginLeft: "20px",
        }}
      >
        <div className="name air">{props.title}</div>
        <div className="name air sub">
          {props.data}
        </div>
      </div>
    </div>
  );
};

export default AirSection;