import React, { useEffect, useState } from "react";
import "../../style/css/style.css";
import Title from "./Title";
import { getSubwayData } from "../../api/network";

const Subway = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getSubwayData(setData);
  }, []);

  return (
    <div className="subway_main">
      <Title name={data?.title} />
        {data ? (
          <div className="subway_list">
          {data.dataList?.map((item, index) => {
            return (
              <div key={index} className="subway_list_item">
                <span>{item.title}</span>
                <span>{item.data}</span>
              </div>
            );
          })}
        </div>
        ) : (
          <h4>조회 결과가 없습니다.</h4>
        )}
    </div>
  );
};

export default Subway;