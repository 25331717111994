import React, { useEffect, useState } from "react";
import "../../style/css/style.css";
import noImage from "../../style/img/noImage.png";
import Title from "./Title";
import { getEventData } from "../../api/network";

const Event = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getEventData(setData);
  }, []);

  return (
    <div className="tour_main">
      <Title name={data?.title} />
        {data ? (
          <div className="tour_image_list">
            {data.dataList?.map((item, index) => (
              <div key={index}>
                <img
                  className="tour_image_list_item"
                  src={item.imageUrl ? item.imageUrl : noImage}
                  alt={item.title}
                  loading="lazy"
                />
                <br />
                {item.title}
                <br />
                {item.data}
              </div>
            ))}
          </div>
        ) : (
          <h4>조회 결과가 없습니다.</h4>
        )}
    </div>
  );
}
export default Event;